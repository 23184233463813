const DOMAIN = "brale.xyz";

export const EMAILS = {
  support: `support@${DOMAIN}`,
  legal: `legal@${DOMAIN}`,
};

export const CONTACT = {
  title: "Brale",
  license: "NMLS ID #2376957",
  address: ["Brale Inc.", "1301 Locust St, Suite C", "Des Moines, IA 50309"],
  fax: "(515) 705-0683",
  phone: "(515) 272-5221",
};

export const BLOG_POSTS = [
  "sbc-integrates-layerzero-for-cross-chain-transfers",
  "announcing-support-for-vechain",
  "five-steps-to-create-and-test-a-custom-stablecoin",
  "mxne-is-live-on-base",
  "upcoming-conferences-for-brale",
  "q1-2025-hiring-cohort",
  "introducing-day-zero",
  "costs-to-send-1k-on-leading-blockchains",
  "rolling-out-sbc-as-an-independent-entity",
  "announcing-support-for-coreum",
  "announcing-support-for-hedera",
  "launching-stablecoins-on-alchemy-rollups",
  "october-conferences-for-brale",
  "stablecoin-token-standards-on-leading-blockchains",
  "stablecoin-micropayments",
  "announcing-support-for-usdc-on-base-arbitrum-celo",
  "introducing-brale-business-accounts",
  "costs-to-send-1m-on-leading-blockchains",
  "our-q3-hiring-cohort",
  "announcing-support-for-canton-network",
  "mint-and-redeem-stablecoins-with-ach-via-plaid",
  "introducing-mxne",
  "introducing-classic-usd",
  "demystifying-onramps-and-offramps",
  "announcing-support-for-usdp",
  "announcing-support-for-ethereum-classic",
  "the-stablecoin-on-ramp",
  "how-to-launch-a-stablecoin",
  "bitgo-stablecoin-support",
  "stablecoin-issuance-cli",
  "send-stablecoins-by-email",
  "stablecoin-issuance-api",
  "announcing-support-for-arbitrum",
  "costs-to-transfer-1m-on-chain",
  "coinflow-realtime-stablecoin-payouts",
  "attestations",
  "introducing-stablecoin-issuance-api",
  "mpc-stablecoin-vault",
  "rain-cards",
  "announcing-support-for-solana",
  "citizen-wallet",
  "brale-ecosystem",
  "treasury-beta",
  "blockchain-speed-cost-comparisons",
  "announcing-support-for-optimism",
  "announcing-support-for-base",
  "announcing-support-for-stellar",
  "our-approach-to-attestations",
  "introducing-treasury",
  "announcing-support-for-celo",
  "tools-for-testing-bespoke-stablecoins-or-digital-assets",
  "base-beta",
  "stable-coin-sbc-white-paper",
  "introducing-stable-coin-sbc",
  "brale-fundraising",
  "understanding-private-blockchains",
  "abstracting-blockchain-gas-fees",
  "brale-base",
  "blockchain-speed-and-cost-comparisons",
  "context",
  "we-are-brale",
] as const;
export type BlogPost = (typeof BLOG_POSTS)[number];

export const SITE = {
  company: "Brale",
  desc: "Create a stablecoin today and generate revenue for your community. Launch a regulated stablecoin supported by Brale’s ecosystem of on ramps, custodians, and payments.",
  domain: DOMAIN,
  image: {
    alt: "Brale combines DeFi and CeFi.",
    path: "/assets/site/global-og-image.png",
  },
  social: {
    twitter: "@brale_xyz",
    opensea: "https://opensea.io/collection/brale/",
  },
  title: "Brale - Create your own stablecoin",
};

export const SOCIAL = {
  linkedin: "https://www.linkedin.com/in",
  opensea: "https://opensea.io/assets",
  twitter: "https://twitter.com",
};

export const BLOG = {
  authors: `/assets/blog/authors`,
  title: "Brale Blog",
};

export const UTF8 = "utf8";

const ROUTES = [
  "about",
  "blog",
  "businessUserAgreement",
  "cantonNetwork",
  "careers",
  "caseStudies",
  "chains",
  "contact",
  "developers",
  "disclosures",
  "ecosystem",
  "endUserAgreement",
  "ethereumClassic",
  "etherfuse",
  "events",
  "feed",
  "gloDollar",
  "home",
  "ioUserAgreement",
  "legal",
  "pricing",
  "product",
  "privacy",
  "security",
  "sitemap",
  "stablecoinRevenueEarlyProgram",
  "stablecoinRevenueMatureProgram",
  "stablecoins",
  "stellar",
  "terms",
  "usdcPayoutCost",
] as const;
export type Route = (typeof ROUTES)[number];

type RouteData = Readonly<{
  desc: string;
  path: string;
  title: string;
  ogImage?: string;
}>;

type Routes = Record<Route, RouteData>;

export const CANONICAL_BASE_URL = `https://${DOMAIN}`;

export const RESPONSES = {
  notFound: () => new Response("Not Found", { status: 404 }),
};

export const ROUTE_DATA: Routes = {
  about: {
    desc: "Brale creates technology that makes DeFi approachable for the next billion users. We believe that combining CeFi and DeFi through stablecoin technology will accelerate markets globally.",
    path: "about",
    title: getTitle("About"),
  },
  blog: {
    desc: "Updates about the Brale Stablecoin Issuance API and stablecoin ecosystem updates, with highlights that make stablecoins easier to create, understand, and manage.",
    path: "blog",
    title: getTitle("Stablecoin Blog"),
  },
  businessUserAgreement: {
    desc: getDefaultDesc("Business User Agreement"),
    path: "legal/business-user-agreement",
    title: getTitle("Business User Agreement"),
  },
  cantonNetwork: {
    desc: "Discover how Brale enabled Canton Network's native stablecoin and onramp, supporting real-time settlement and secure connectivity for regulated financial institutions.",
    path: "case-studies/canton-network",
    title: getTitle("Canton Case Study"),
    ogImage: "/assets/case-studies/canton-network/og-image.png",
  },
  careers: {
    desc: "Learn about careers at Brale. If you are interested in opportunities at Brale, please contact our team.",
    path: "careers",
    title: getTitle("Careers"),
    ogImage: "/assets/careers/og-image.png",
  },
  caseStudies: {
    desc: "Hear about our customers",
    path: "case-studies",
    title: getTitle("Case Studies"),
  },
  chains: {
    desc: "View blockchain market data for high quality, regulated stablecoins.",
    path: "chains",
    title: getTitle("Blockchains"),
  },
  contact: {
    desc: getDefaultDesc("Contact Brale"),
    path: "contact",
    title: getTitle("Contact"),
  },
  developers: {
    desc: "Learn about the Brale Stablecoin API.",
    path: "developers",
    title: getTitle("Developers"),
  },
  disclosures: {
    desc: getDefaultDesc("Disclosures"),
    path: "legal/disclosures",
    title: getTitle("Disclosures"),
  },
  ecosystem: {
    desc: "Brale makes it easy to issue regulated stablecoins to benefit your business. Explore our ecosystem of stablecoins, wallets, and applications.",
    path: "ecosystem",
    title: getTitle("Ecosystem"),
    ogImage: "/assets/ecosystem/og-image.png",
  },
  endUserAgreement: {
    desc: getDefaultDesc("End User Agreement"),
    path: "legal/end-user-agreement",
    title: getTitle("End User Agreement"),
  },
  ethereumClassic: {
    desc: "Learn how Brale partnered with Ethereum Classic to issue Classic USD, the premier stablecoin on the network.",
    path: "case-studies/ethereum-classic",
    title: getTitle("Ethereum Classic Case Study"),
    ogImage: "/assets/case-studies/ethereum-classic/og-image.png",
  },
  etherfuse: {
    desc: "Explore how Brale partnered with Etherfuse to launch MXNe, the first Mexican Peso stablecoin on Solana and Stellar.",
    path: "case-studies/etherfuse",
    title: getTitle("Etherfuse Case Study"),
    ogImage: "/assets/case-studies/etherfuse/og-image.png",
  },
  events: {
    desc: "Day Zero Conference: Where stablecoin builders converge to build the future of money.",
    path: "events",
    title: getTitle("Day Zero Conference"),
    ogImage: "/assets/events/og-image.png",
  },
  feed: {
    desc: getDefaultDesc("RSS Feed"),
    path: "feed.xml",
    title: getTitle("RSS Feed"),
  },
  gloDollar: {
    desc: "Discover how Brale helped Glo Foundation quickly launch Glo Dollar, a multi-chain community currency stablecoin supporting public goods and charities.",
    path: "case-studies/glo-dollar",
    title: getTitle("Glo Dollar Case Study"),
    ogImage: "/assets/case-studies/glo-dollar/og-image.png",
  },
  home: {
    desc: SITE.desc,
    path: "",
    title: getTitle("Create your own stablecoin"),
  },
  ioUserAgreement: {
    desc: getDefaultDesc("IO User Agreement"),
    path: "legal/io-user-agreement",
    title: getTitle("IO User Agreement"),
  },
  legal: {
    desc: getDefaultDesc("Legal"),
    path: "legal/terms",
    title: getTitle("Legal"),
  },
  pricing: {
    desc: "Create and grow your stablecoin. Upgrade to enable custom blockchains, third party reserve reports, custom reserve infrastructure, and additional features.",
    path: "pricing",
    title: getTitle("Pricing"),
  },
  privacy: {
    desc: getDefaultDesc("Privacy Policy"),
    path: "legal/privacy",
    title: getTitle("Privacy Policy"),
  },
  product: {
    desc: "Explore Brale’s platform for creating stablecoins. Create a stablecoin backed by regulated reserves and list on Brale’s exchange. Launch with day one ecosystem integrations.",
    path: "product",
    title: getTitle("Product"),
  },
  security: {
    desc: "Learn how Brale approaches enterprise-grade security to protect users. View our asset security, third-party audits, risk management, and more.",
    path: "security",
    title: getTitle("Security"),
  },
  sitemap: {
    desc: getDefaultDesc("Sitemap"),
    path: "sitemap.xml",
    title: getTitle("Sitemap"),
  },
  stablecoinRevenueEarlyProgram: {
    desc: "Stablecoin revenue projections for an early program.",
    path: "calculator/stablecoin-revenue-early-program",
    title: getTitle("Stablecoin Revenue - Early Program"),
  },
  stablecoinRevenueMatureProgram: {
    desc: "Stablecoin revenue projections for a mature program.",
    path: "calculator/stablecoin-revenue-mature-program",
    title: getTitle("Stablecoin Revenue - Mature Program"),
  },
  stablecoins: {
    desc: "View stablecoins including USDC, USDP, and SBC. Learn about stablecoins supply, chains issued on, attestation reports, and other information.",
    path: "stablecoins",
    title: getTitle("Stablecoins"),
    ogImage: "/assets/products/og-image-coin-view.png",
  },
  stellar: {
    desc: getDefaultDesc("Stellar"),
    path: "stellar.toml",
    title: getTitle("Stellar"),
  },
  terms: {
    desc: getDefaultDesc("Terms of Service"),
    path: "legal/terms",
    title: getTitle("Terms of Service"),
  },
  usdcPayoutCost: {
    desc: "Calculate the costs associated with USDC payouts including ramp fees and platform fees.",
    path: "calculator/usdc-payout-cost",
    title: getTitle("USDC Payout Cost"),
  },
};

export function getDefaultDesc(title: string) {
  return `${SITE.company} ${title}. ${SITE.desc}`;
}

export function getTitle(title: string) {
  return `${title} | ${SITE.company}`;
}
